import React from "react";
import { bannerImg } from "../../assets";
import { about } from "../../assets";


const Aboutleft = () => {
  return (
        <div className="w-full md:w-1/2 flex justify-center items-center relative">
          <img
            className="w-[200px] h-[200px] sm:w-[340px] sm:h-[310px] md:w-[280px] md:h-[280px] lg:w-[430px] lg:h-[460px] lg:mt-16 z-10"
            src={about}
            alt="bannerImg"
          />
          <div className="absolute bottom-0 w-[230px] h-[220px] sm:w-[380px]  sm:h-[200px] md:w-[300px] md:h-[300px] lg:w-[480px] lg:h-[500px] bg-gradient-to-r from-[#1e2024] to-[#202327] shadow-shadowOne flex justify-center items-center"></div>
        </div>
  );
};

export default Aboutleft;
