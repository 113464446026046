import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import { MdClose } from "react-icons/md";
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { logo } from "../../assets/index";
import { navLinksdata } from "../../constants";
import cv from "../../assets/kasher_resume.pdf"; 

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [activeLink, setActiveLink] = useState(null);

  return (
    <div className="w-full h-24 top-0 z-50 bg-bodyColor mx-auto flex justify-between items-center font-titleFont border-b-[1px] border-b-gray-600">
      <div className="sm:ml-10 ml-5">
        <img src={logo} alt="logo" className="sm:h-24" />
      </div>
      <div>
        <ul className="hidden mdl:inline-flex items-center gap-6 mr-10 lg:gap-10">
          {navLinksdata.map(({ _id, title, link }) => (
            <li
              className={`text-base font-normal tracking-wide cursor-pointer hover:text-designColor duration-300 ${
                activeLink === _id ? "font-bold text-designColor " : "text-gray-400"
              }`}
              key={_id}
              onClick={() => setActiveLink(_id)}
            >
              <Link to={link}>{title}</Link>
            </li>
          ))}
          <li>
            <a
              href={cv}
              download="My_CV.pdf"
              className="text-base font-normal tracking-wide text-gray-400 hover:text-designColor duration-300"
            >
              Download CV
            </a>
          </li>
        </ul>
        <span
          onClick={() => setShowMenu(!showMenu)}
          className="text-xl mdl:hidden bg-black w-10 h-10 inline-flex items-center justify-center rounded-full text-designColor cursor-pointer"
        >
          <FiMenu />
        </span>
        {showMenu && (
          <div className="w-[80%] h-screen overflow-scroll absolute top-0 left-0 bg-gray-900 p-4 scrollbar-hide z-50">
            <div className="flex flex-col gap-8 py-2 relative">
              <div>
                <img className="w-32" src={logo} alt="logo" />
              </div>
              <ul className="flex flex-col gap-4">
                {navLinksdata.map(({ _id, title, link }) => (
                  <li
                    key={_id}
                    className={`text-base font-normal tracking-wide cursor-pointer hover:text-designColor duration-300 ${
                      activeLink === _id ? "font-bold text-designColor " : "text-gray-400"
                    }`}
                    onClick={() => {
                      setActiveLink(_id);
                      setShowMenu(false);
                    }}
                  >
                    <Link to={link}>{title}</Link>
                  </li>
                ))}
                  <li>
            <a
              href={cv}
              download="My_CV.pdf"
              className="text-base font-normal tracking-wide text-gray-400 hover:text-designColor duration-300"
            >
              Download CV
            </a>
          </li>
              </ul>
              <div className="flex flex-col gap-4">
                <h2 className="text-base uppercase font-titleFont mb-4">
                  Find me in
                </h2>
                <div className="flex gap-4">
                  <span className="bannerIcon">
                    <FaFacebookF />
                  </span>
                  <span className="bannerIcon">
                    <FaTwitter />
                  </span>
                  <span className="bannerIcon">
                    <FaLinkedinIn />
                  </span>
                </div>
              </div>
              <span
                onClick={() => setShowMenu(false)}
                className="absolute top-4 right-4 text-gray-400 hover:text-designColor duration-300 text-2xl cursor-pointer"
              >
                <MdClose />
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
