import { useState } from "react";
import Reactpro from "./Reactpro";
import Webpro from "./Webpro";
import Uipro from "./Uipro";
import Blogpro from "./Blogpro";
import Title  from "../layouts/Title";

const Project = () => {
  const [reactData, setReactData] = useState(true);
  const [webData, setWebData] = useState(false);
  const [blogData, setBlogData] = useState(false);
  const [uiData, setUiData] = useState(false);

  return (
    <section id="resume" className="w-full py-10 border-b-[1px] border-b-black">
      <div className="flex justify-center items-center text-center">
        <div className="mb-10">
          <Title title="Projects are available on my Github" des="My Projects" />
        </div>
      </div>
      <div>
        <ul className="w-full grid grid-cols-4 text-center md:flex md:justify-around md:items-center gap-4">
          <li
            onClick={() => {
              setReactData(true);
              
              setWebData(false);
              setBlogData(false);
              setUiData(false);
            }}
            className={`${
              reactData ? "border-designColor rounded-lg" : "border-transparent"
            } resumeLi text-xxs sm:text-xl rounded-xl`}
          >
            React Js
          </li>
          <li
            onClick={() => {
              setWebData(true);
              
              setReactData(false);
              setBlogData(false);
              setUiData(false);
            }}
            className={`${
              webData ? "border-designColor rounded-lg" : "border-transparent"
            } resumeLi text-xxs sm:text-xl rounded-xl`}
          >
            Websites
          </li>
          <li
            onClick={() => {
              setBlogData(true);
              
              setReactData(false);
              setWebData(false);
              setUiData(false);
            }}
            className={`${
              blogData ? "border-designColor rounded-lg" : "border-transparent"
            } resumeLi text-xxs sm:text-xl rounded-xl`}
          >
            Blogging
          </li>
          <li
            onClick={() => {
              setUiData(true);
              
              setReactData(false);
              setWebData(false);
              setBlogData(false);
            }}
            className={`${
              uiData ? "border-designColor rounded-lg" : "border-transparent"
            } resumeLi text-xxs sm:text-xl rounded-xl`}
          >
            Ui Designs
          </li>
        </ul>
      </div>
      {reactData && <Reactpro />}
      {webData && <Webpro />}
      {blogData && <Blogpro />}
      {uiData && <Uipro />}
    </section>
  );
};

export default Project;
