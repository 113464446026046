import React from "react";

const Aboutright = () => {
  return (
    <div className="w-full lgl:w-1/2 flex flex-col gap-20">
      <div className="flex flex-col gap-5">
        <h4 className=" sm:text-lg  font-bold">UNITY | FAITH | DISCIPLINE</h4>
        <h1 className="sm:text-4xl text-md font-bold text-white">
          MYSELF ,
          <span className="text-designColor capitalize"> KASHER SHAHZAD</span>
        </h1>
        <p className="text-sm lgl:text-lg font-bodyFont leading-6 tracking-wide">
          An undergraduate student, pursuing Bechelor of Computer Science(BSCS)
          . Holds Full Stack Web Developer <span className="text-designColor capitalize">certification</span> from ServingSol. Now I
          am working in <span className="text-designColor capitalize"> react Js</span> and have a strong concept of JavaScript. I’m
          skilled at building interactive web applications. <br />
          <br /> Currently working with ServingSol as a Web Developer.
          Contribution in successful execution of <span className="text-designColor capitalize">numerous projects</span> with
          ServingSol. Working collaboratively with my team. Also, dedicated to
          teach the next Web developers as a Teacher at ServingSol's dynamic
          learning community. <br />
          <br /> Technology excites me, I find inspiration from the latest
          developments in the tech world, I always want to make meaningful <span className="text-designColor capitalize">
          contributions</span> that play a vital role in the tech landscape and leave a
          lasting impact on the world.
        </p>
      </div>
    </div>
  );
};

export default Aboutright;
