import React from "react";
import { night, rise} from "../../assets/index";
import ProjectsCard from "./ProjectsCard";

const Blogpro = () => {
  return (
    <div className="w-full py-10 border-b-[1px] border-b-black">
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14">
        <ProjectsCard
          title="Night Vision"
          des="The best of Blogging, with a focus on night vision intoduction , their produts and everything relates to them"
          src={night}
        />
        <ProjectsCard
          title="Rise Buds"
          des="The best of Blogging, with a focus on Health, Lifestyle, Fitness, Tech, Business, Beauty, and Food."
          src={rise}
        />
      </div>
    </div>
  );
};

export default Blogpro;
