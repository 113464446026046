import React from "react";
import Aboutleft from "./Aboutleft";
import Aboutright from "./Aboutright";
import Title from "../layouts/Title";

const About = () => {
  return (
    <>
      <div className="mt-10 sm:mb-10 sm:ml-10">
        <Title className="" title="Introduction" des="About Me" />
      </div>
      <section
        id="home"
        className="w-full pt-10 pb-20 flex flex-col gap-10 xl:gap-0 lgl:flex-row border-b-[1px] font-titleFont border-b-black"
      >
        <Aboutleft />
        <Aboutright />
      </section>
    </>
  );
};

export default About;
