import React from "react";
import { motion } from "framer-motion";
import ResumeCard from "./ResumeCard";

const Experience = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full flex flex-col  lgl:items-center lgl:justify-center lgl:flex-row gap-10 lgl:gap-20"
    >
      {/* part one */}
      <div>
        <div className="py-6 lgl:py-12 font-title Font flex flex-col  gap-4 sm:ml-12">
          <h2 className="text-3xl md:text-4xl font-bold">MY Eduction</h2>
        </div>
        <div className="w-full min-h-screen border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="BS Computer Science (BSCS)"
            subTitle=" (2023  -  ____ )"
            role="Superior Collage"
          />
          <ResumeCard
            title="ICS"
            subTitle=" (2019  - 2023 )"
            role="Independent Collage"
          />
          <ResumeCard
            title="Matric"
            subTitle=" (2017  -  2019)"
            role="Dar-e-Arqam School"
          />
          <ResumeCard
            title="Web Development Course"
            subTitle="(2023)"
            role="ServingSol"
          />
        </div>
      </div>
    </motion.div>
  );
};

export default Experience;
