import React from "react";
import ProjectsCard from "./ProjectsCard";
import { ServingSol, weather, expence, list, multi } from "../../assets/index";

const Reactpro = () => {
  return (
    <div className="w-full py-10 border-b-[1px] border-b-black">
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14">
        <ProjectsCard
          title="ServingSol Website"
          des="It is complettly responvie website of a softwere house. It contain dark mode and all the features a modren website requid in today's date"
          src={ServingSol}
        />
        <ProjectsCard
          title="Weather App"
          des="In this app you can search city. You can see temperature , real Feel , humidity etc. The backgoround changes according to the weather"
          src={weather}
        />
        <ProjectsCard
          title="Expence manager"
          des="You can see all your Expences , Income and Current balance You can see your transaction History and also delete it. It also delete form your records."
          src={expence}
        />
        <ProjectsCard
          title="To-do List"
          des="A todo list app which is completly responsive using react + vite. You can add your task and it displays your tasks. You can Edit , Delete or clear the complete list."
          src={list}
        />
        <ProjectsCard
          title="Multi page site"
          des="A multiple pages website app which is completly responsive using react app. You can see Multiple pages in navbar which are connected with react-router-dom"
          src={multi}
        />
      </div>
    </div>
  );
};

export default Reactpro;
