import React from "react";
import { motion } from "framer-motion";
import ResumeCard from "./ResumeCard";

const Education = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full flex flex-col  lgl:items-center lgl:justify-center lgl:flex-row gap-10 lgl:gap-20"
    >
      {/* part one */}
      <div>
        <div className="py-6 lgl:py-12 font-title Font flex flex-col  gap-4 sm:ml-12">
          <h2 className="text-3xl md:text-4xl font-bold">MY Experience</h2>
        </div>
        <div className="w-full min-h-screen border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard title="ServingSol" subTitle=" (2023  -  ____ )" />
          <ResumeCard
            role="Web Developer"
            des="I Joined The Team Of ServingSol And Contributing To The Successful Completion Of Multiple Projects."
          />
          <ResumeCard
            role="SOCIAL MEDIA MARKETER"
            result="5.00/5"
            des="Managing Social Media Platforms With Content Creation And Engagement Using Canva Proficiency."
          />
          <ResumeCard
            role="IT INSTRUCTOR"
            result="5.00/5"
            des="Dedicated To Teach The Next Web Developers As A Teacher At ServingSol's Dynamic Learning Community."
          />
          <ResumeCard
            role="Graphic Design"
            result="4.75/5"
            des="I Joined The Team Of ServingSol And design posters and logos,I’m skilled at turning ideas into attractive designs"
          />
          <ResumeCard
            role="BLOGGING"
            result="5.00/5"
            des="I write content on sites and Running And Managing Sites For ServingSol"
          />
        </div>
      </div>
    </motion.div>
  );
};

export default Education;
