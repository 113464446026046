import React from "react";
import {
  des1,
  des10,
  des11,
  des12,
  des13,
  des14,
  des15,
  des16,
  des17,
  des2,
  des3,
  des4,
  des5,
  des6,
  des7,
  des8,
  des9,
} from "../../assets/index";
import ProjectsCard from "./ProjectsCard";

const Uipro = () => {
  return (
    <div className="w-full py-10 border-b-[1px] border-b-black">
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14">
        <ProjectsCard title="Marketing Post" des="" src={des1} />
        <ProjectsCard title="Marketing Post" des="" src={des2} />
        <ProjectsCard title="Marketing Post" des="" src={des3} />
        <ProjectsCard title="Marketing Post" des="" src={des4} />
        <ProjectsCard title="Marketing Post" des="" src={des5} />
        <ProjectsCard title="Marketing Post" des="" src={des6} />
        <ProjectsCard title="Informative post" des="" src={des7} />
        <ProjectsCard title="Marketing Post" des="" src={des8} />
        <ProjectsCard title="Marketing Post" des="" src={des9} />
        <ProjectsCard title="Marketing Post" des="" src={des10} />
        <ProjectsCard title="logo" des="" src={des11} />
        <ProjectsCard title="logo" des="" src={des12} />
        <ProjectsCard title="Marketing Post" des="" src={des13} />
        <ProjectsCard title="logo" des="" src={des14} />
        <ProjectsCard title="logo" des="" src={des15} />
        <ProjectsCard title="logo" des="" src={des16} />
        <ProjectsCard title="event post" des="" src={des17} />
      </div>
    </div>
  );
};

export default Uipro;
