import React from "react";
import {
  FaLinkedinIn,
  FaReact,
  FaGithub,
  FaInstagram,
} from "react-icons/fa";
import {
  SiTailwindcss,
  SiJavascript,
  SiCanva,
} from "react-icons/si";
const Media = () => {
  return (
    <div className="flex flex-col lg:gap-0 justify-between">
      <div className=" xl:mb-0">
        <h2 className="text-base uppercase font-titleFont mb-4">Find me in</h2>
        <div className="flex gap-4">
          <a
            href="https://www.linkedin.com/in/kasher-shahzad-223579288/"
            target="_blank"
            rel="noopener noreferrer"
            className="bannerIcon hover:text-customBlue"
          >
            <FaLinkedinIn />
          </a>
          <a
            href="https://github.com/Kasher503"
            target="_blank"
            rel="noopener noreferrer"
            className="bannerIcon  hover:text-white"
          >
            <FaGithub />
          </a>
          <a
            href="https://www.instagram.com/kasher_shahzad/"
            target="_blank"
            rel="noopener noreferrer"
            className="bannerIcon"
          >
            <FaInstagram />
          </a>
        </div>
      </div>
      <div>
        <h2 className="text-base uppercase font-titleFont mb-4 mt-10">
          BEST SKILL ON
        </h2>
        <div className="flex gap-4">
          <span className="bannerIcon hover:text-skyblue">
            <FaReact />
          </span>
          <span className="bannerIcon hover:text-yellow-300">
            <SiJavascript />
          </span>
          <span className="bannerIcon hover:text-cyan-500">
            <SiTailwindcss />
          </span>
          <span className="bannerIcon hover:text-cyan-500">
            <SiCanva />
          </span>
        </div>
      </div>
    </div>
  );
};

export default Media;
