import React from "react";
import {
  portfolio1,
  portfolio2,
  portfolio3,
  portfolio4,
  reg,
  reg2,
  ococi,
  game1,
  travel,
  game2,
  blood,
  ancory,
} from "../../assets/index";
import ProjectsCard from "./ProjectsCard";

const Webpro = () => {
  return (
    <div className="w-full py-10 border-b-[1px] border-b-black">
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14">
        <ProjectsCard
          title="Tic-Tac-Toe Game"
          des="A game which is made in javaScript"
          src={game1}
        />
        <ProjectsCard
          title="Rock-Paper- Game"
          des="A game which is made in javaScript"
          src={game2}
        />
        <ProjectsCard
          title="Ancory Tech"
          des=" AI Content Writer Website"
          src={ancory}
        />
        <ProjectsCard
          title="Love Pulse Walfare"
          des="Blood Bank"
          src={blood}
        />
        <ProjectsCard
          title="OCOCI"
          des="An Award show website"
          src={ococi}
        />
        <ProjectsCard
          title="Portfolio"
          des="A Protfolio website"
          src={portfolio1}
        />
        <ProjectsCard
          title="Portfolio"
          des="A Protfolio website"
          src={portfolio2}
        />
        <ProjectsCard
          title="Portfolio"
          des="A Protfolio website"
          src={portfolio3}
        />
        <ProjectsCard
          title="Portfolio"
          des="A Protfolio website"
          src={portfolio4}
        />
        <ProjectsCard
          title="FAlcon Travels"
          des="A Travelling website"
          src={travel}
        />
        <ProjectsCard title="Registration Form" des="" src={reg} />
        <ProjectsCard title="Registration Form" des="" src={reg2} />
      </div>
    </div>
  );
};

export default Webpro;
