import { AiFillAppstore } from "react-icons/ai";
import { FaMobile, FaGlobe, FaChalkboardTeacher} from "react-icons/fa";
import { SiProgress, SiAntdesign, SiReact, SiBlogger } from "react-icons/si";

// Features Data
export const featuresData = [
  {
    id: 1,
    title: "Web Developer",
    des: "I Joined The Team Of ServingSol And Contributing To The Successful Completion Of Multiple Projects",
  },
  {
    id: 2,
    icon: <SiReact/>,
    title: "React Js",
    des: "Currently working in react Js and have a strong concept of JavaScript. I’m skilled at building interactive web applications.",
  },
  {
    id: 3,
    icon: <SiBlogger />,
    title: "BLOGGER",
    des: "I Gain Valuable Expertise In Search Engine Optimization (SEO) And Also Running And Managing Sites For ServingSol",
  },
  {
    id: 4,
    icon: <FaChalkboardTeacher />,
    title: "IT INSTRUCTOR",
    des: "Dedicated To Teach The Next Web Developers As A Teacher At ServingSol's Dynamic Learning Community.",
  },
  {
    id: 5,
    icon: <SiAntdesign />,
    title: "UI Design",
    des: "I have experience designing posters and logos,I’m skilled at turning ideas into attractive designs",
  },
];
